import { Status as StatusComponent } from '@pretto/zen/atoms/infos/Status/Status'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import styled from 'styled-components'

export const Label = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.neutral3}`};
  border-radius: ${g(1)};
  margin-top: ${({ $isOnTheRight }) => $isOnTheRight && `${g(4)}`};
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-column: span 4;
    padding: ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-column: span 5;
    margin-top: 0;
  }
`

export const Labels = styled.div`
  ${column([2, 4])};
  ${grid(1, { isLeftAligned: true, isRightAligned: true })};
  row-gap: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    ${grid(8, { isLeftAligned: true, isRightAligned: true })};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
    ${grid(10, { isLeftAligned: true, isRightAligned: true })};
  }
`

export const Status = styled(StatusComponent)`
  align-self: flex-start;
  background-color: ${({ theme, $theme }) => ($theme === 'dark' ? theme.colors.primary2 : theme.colors.primary4)};
  color: ${({ theme, $theme }) => ($theme === 'dark' ? theme.colors.white : theme.colors.primary1)};
  flex-shrink: 0;
  margin-bottom: ${g(2)};
  margin-left: auto;
  margin-right: ${g(-1)};
  margin-top: ${g(-1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
    margin-left: ${g(2)};
  }
`

export const Suptitle = styled(BaseTypo).attrs({ typo: 'headline16' })`
  ${column([2, 4])};
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
  }
`

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    justify-content: space-between;
    flex-direction: row;
  }
`

import { Grid } from '@pretto/bricks/components/layout/Grid'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import RichContent from '@pretto/bricks/website/about/components/RichContent'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const BlocLabels = ({ labels, suptitle, ...props }) => (
  <Grid {...props}>
    {suptitle && <S.Suptitle>{suptitle}</S.Suptitle>}

    <S.Labels>
      {labels.map(({ title, content, status }, index) => (
        <S.Label key={index}>
          <S.TopWrapper>
            <BaseTypo as="h2" typo="heading32">
              {title}
            </BaseTypo>
            <S.Status $theme={status.theme}>{status.label}</S.Status>
          </S.TopWrapper>

          <RichContent allowedTypes={['a', 'li', 'strong', 'ul']}>{content}</RichContent>
        </S.Label>
      ))}
    </S.Labels>
  </Grid>
)

BlocLabels.propTypes = {
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      /** Text content */
      content: PropTypes.string.isRequired,
      /** Labels status */
      status: PropTypes.shape({
        label: PropTypes.string.isRequired,
        theme: PropTypes.oneOf(['dark', 'light']).isRequired,
      }),
      /** Text of title */
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  suptitle: PropTypes.string,
}

export default memo(BlocLabels)
